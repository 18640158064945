@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&family=Nunito:wght@200;300;400;500;600;700;800;900&family=Open+Sans:wght@300;400;500;600;700;800&family=Raleway:wght@100;200;300;400;500;600;700;800;900&family=Rubik:wght@300;400;500;600;700;800;900&family=Sono:wght@200;300;400;500;600;700;800&display=swap");

* {
  font-family: "Rubik", sans-serif;
}

body {
  margin: 0;
  /* font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media print {
  body * {
    visibility: hidden;
  }
}
/* Your CSS file */
body {
  user-select: none;
  -webkit-user-select: none; /* For Safari */
}

button {
  font-family: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* #react-select-5-listbox {
  z-index: 999999999999999 !important;
} */
@media screen and (max-width: 500px) {
  .m-hidden {
    display: none;
  }
  .exam-questions-view-panel{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  } 
  .job-batch-card{
    clip-path: polygon(0 1%, 98% 0, 54% 46%, 25% 71%, 0 91%, 0% 50%);
  }
}

.exam-questions-view-panel::-webkit-scrollbar {
  display: none;
}
.exam-questions-view-panel {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.job-batch-card{
  clip-path: polygon(0 1%, 98% 0, 54% 46%, 25% 71%, 0 91%, 0% 50%);
}
div.password-wrapper p{
  @apply hidden;
}
