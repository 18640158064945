.Sidebar {
	top: 43px;
	transition: all ease-in-out 0.4s;
	font-size:12px;
	background-color: #fff;
}

.OpenSidebar {
	transform: translateX(0);
	font-size:12px;
}

.CloseSidebar {
	transform: translateX(-100%);
	font-size:12px;
}

/* Style the scrollbar */
::-webkit-scrollbar {
	/* Set the width of the scrollbar */
	width: 5px !important;
	height: 5px !important;
  }
  
  /* Style the scrollbar track */
  ::-webkit-scrollbar-track {
	/* Set the background color of the scrollbar track */
	background-color: white;
  }
  
  /* Style the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
	/* Set the color of the scrollbar thumb */
	background-color: #9ab767;
	border-radius: 20px;
	width: 5px !important;
  }
  
  /* Style the scrollbar thumb on hover */
  ::-webkit-scrollbar-thumb:hover {
	/* Set the color of the scrollbar thumb on hover */
	background-color:  #9ab767;
  }

   @media only screen and (max-width: 500px){
	::-webkit-scrollbar {
		/* Set the width of the scrollbar */
		width: 2px !important;
		margin-top: 5px;
	  }
   }
  
  
  