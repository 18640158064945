@import "~react-phone-number-input/style.css";
.PhoneInputInput {
  background-color: transparent;
  /* padding-top: 1rem; */
  /* padding-bottom: 1rem; */
  padding-left: 1rem;
  padding-right: 1rem;
  color: #374151;
  font-size: 1rem;
  line-height: 1.5rem;
  flex: 1 1 0%;
  width: 100%;
  border: none;
  /* border-radius: 0.5rem; */
  /* border-width: 1px; */
  appearance: none;
  /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); */
}
.PhoneInputInput:focus {
  outline: none;
}
.react-datepicker-wrapper {
}
.react-datepicker__input-container {
}

.react-datepicker__input-container > input {
  background-color: transparent !important;
  width: 100%;
  cursor: pointer;
}

.react-datepicker-ignore-onclickoutside {
  border: none;
}
.react-datepicker-ignore-onclickoutside:focus {
  outline: none;
}
.react-datepicker__month-container {
  width: 400px;
}
