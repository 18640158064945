div.result-panel{
    overflow: hidden;
}
.green-radio:checked {
    background-color: #0aca0a !important;
    border-color: rgb(9, 219, 9) !important;
  }
  span.answer-wrapper p{
    font-size: 14px !important;
  }

  section.candidate-assessment > *{
     overflow-x: hidden;
  }