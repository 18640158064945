.SidebarItem {
	padding: 10px 8px;
	border-radius: 4px;
	color: rgba(75, 87, 87, 0.867);
	margin-bottom: 1rem;
	cursor: pointer;
	font-weight: 400;
	font-size: 14px;
	transition: all ease-in-out 0.2s;
	display: flex;
	align-items: center;
	position: relative;
	border:1px solid transparent;
}

/* .SidebarItem::after {
	content: '';
	position: absolute;
	right: -5%;
	height: 100%;
	width: 5px;
	background: transparent;
} */

.SidebarItem[data-active='true'] {
	color: #87aa4b;
	/* font-weight: 600; */
	background-color: #f2f3f7;
	border:1px solid #ccc;
}

.SidebarItem > span {
	margin-left: 10px;
}

.SidebarItem:hover {
	background-color: #f2f3f7;
}

.SidebarItem[data-active='true'].SidebarItem::after {
	background: #8eaf55;
}
